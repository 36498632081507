import { isEmpty } from 'lodash-es'

export const state = () => ({
  canceled_reasons: {},
  delivery_method: {},
  processed_types: {},
  order_status: {},
  payment_method: {},
  arriving_points: {},
  delivery_types: {},
  fan_currier_counties_and_locations: [],
  counties: [],
  couriers: [],
  locations: [],
})

export const getters = {
  orderStatusConstants(state) {
    return state.order_status
  },
  deliveryMethodConstants(state) {
    return state.delivery_method
  },
  processedTypesConstants(state) {
    return state.processed_types
  },
  paymentMethodConstants(state) {
    return state.payment_method
  },
  arriving_points(state) {
    return state.arriving_points
  },
  delivery_types(state) {
    return state.delivery_types
  },
  fanCurrierCountiesAndLocations(state) {
    return state.fan_currier_counties_and_locations
  },
  counties(state) {
    return state.counties
  },
  couriers(state) {
    return state.couriers
  },
  locations(state) {
    return state.locations
  },
}

export const mutations = {
  SET_ORDER_STATUS_CONSTANTS(state, orderStatusConstants) {
    state.order_status = orderStatusConstants
  },
  SET_DELIVERY_METHOD_CONSTANTS(state, deliveryMethodConstants) {
    state.delivery_method = deliveryMethodConstants
  },
  SET_PAYMENT_METHOD_CONSTANTS(state, deliveryMethodConstants) {
    state.payment_method = deliveryMethodConstants
  },
  SET_DELIVERY_TYPE_CONSTANTS(state, deliveryTypeConstants) {
    state.delivery_types = deliveryTypeConstants
  },
  SET_ARRVING_POINT_CONSTANTS(state, arrivingPointConstants) {
    state.arriving_points = arrivingPointConstants
  },
  SET_FAN_CURRIER_COUNTIES_AND_LOCATIONS(state, fanCurrierCountiesAndLocations) {
    state.fan_currier_counties_and_locations = fanCurrierCountiesAndLocations
  },
  SET_COUNTIES(state, counties) {
    state.counties = counties
  },
  SET_COURIERS(state, couriers) {
    state.couriers = couriers
  },
  SET_PROCESSED_TYPE_CONSTANTS(state, processedTypeConstants) {
    state.processed_types = processedTypeConstants
  },
  SET_LOCATIONS(state, locations) {
    state.locations = locations
  },
}

export const actions = {
  async getOrderStatusConstants({ state, commit }) {
    if (isEmpty(state.order_status)) {
      const response = await this.$axios.$get('admin/constants/order-status')
      commit('SET_ORDER_STATUS_CONSTANTS', response.data)
    }
  },

  async getDeliveryMethodConstants({ state, commit }) {
    if (isEmpty(state.delivery_method)) {
      const response = await this.$axios.$get('admin/constants/delivery-method')
      commit('SET_DELIVERY_METHOD_CONSTANTS', response.data)
    }
  },

  async getProcessedTypesConstants({ state, commit }) {
    if (isEmpty(state.processed_types)) {
      const response = await this.$axios.$get('admin/constants/processed-type')
      commit('SET_PROCESSED_TYPE_CONSTANTS', response.data)
    }
  },

  async getPaymentMethodConstants({ state, commit }) {
    if (isEmpty(state.payment_method)) {
      const response = await this.$axios.$get('admin/constants/payment-method')
      commit('SET_PAYMENT_METHOD_CONSTANTS', response.data)
    }
  },

  async getDeliveryTypeConstants({ state, commit }) {
    if (isEmpty(state.delivery_types)) {
      const response = await this.$axios.$get('admin/constants/delivery-types')
      commit('SET_DELIVERY_TYPE_CONSTANTS', response.data)
    }
  },

  async getArrivingPointConstants({ state, commit }) {
    if (isEmpty(state.arriving_points)) {
      const response = await this.$axios.$get('admin/constants/arriving-points')
      commit('SET_ARRVING_POINT_CONSTANTS', response.data)
    }
  },

  async getFanCurrierCountiesAndLocations({ state, commit }) {
    if (isEmpty(state.fan_currier_counties_and_locations)) {
      const response = await this.$axios.$get('admin/counties')
      commit('SET_FAN_CURRIER_COUNTIES_AND_LOCATIONS', response.data)
    }
  },
  async getCounties({ state, commit }) {
    if (isEmpty(state.counties)) {
      const response = await this.$axios.$get('admin/counties')
      commit('SET_COUNTIES', response.data)
    }
  },
  async getCouriers({ state, commit }) {
    if (isEmpty(state.couriers)) {
      const response = await this.$axios.$get('admin/constants/couriers')
      commit('SET_COURIERS', response.data)
    }
  },
  async getLocations({ state, commit }) {
    if (isEmpty(state.couriers)) {
      const response = await this.$axios.$get('admin/constants/locations')
      commit('SET_LOCATIONS', response.data)
    }
  },
}
