export const state = () => ({
  types: [],
  diameters: [],
  importers: [],
  manufacturers: []
})

export const getters = {
  types(state) {
    return state.types
  },
  diameters(state) {
    return state.diameters
  },
  importers(state) {
    return state.importers
  },
  manufacturers(state) {
    return state.manufacturers
  }
}

export const mutations = {
  SET_TYPES(state, types) {
    state.types = types
  },
  SET_DIAMETERS(state, diameters) {
    state.diameters = diameters
  },
  SET_IMPORTERS(state, importers) {
    state.importers = importers
  },
  SET_MANUFACTURERS(state, manufacturers) {
    state.manufacturers = manufacturers
  }
}

export const actions = {
  async fetchDeliveryData({ commit }) {
    const response = await this.$axios.$get('admin/general-resources/delivery-data')

    commit('SET_TYPES', response.data.types)
    commit('SET_DIAMETERS', response.data.diameters)
    commit('SET_IMPORTERS', response.data.importers)
    commit('SET_MANUFACTURERS', response.data.manufacturers)
  }
}
