<template>
  <div :id="`VueTables__${props.column}-filter`" class="VueTables__list-filter">
    <v-select :value="selectedValue" :multiple="isMultipleSelect" label="text" :options="props.items" @input="setSearch" />
  </div>
</template>

<script>
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css'

  export default {
    name: 'MyListFilter',
    components: {
      vSelect,
    },
    props: {
      props: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      isMultipleSelect() {
        return Boolean(this.props?.opts?.filterOptions?.[this.props.column]?.isMultipleSelect)
      },
      selectedValue() {
        if (this.isMultipleSelect && Array.isArray(this.props.value)) {
          return this.props.items.filter((option) => this.props.value.includes(option.id))
        }

        return this.props.items.find((option) => option.id === this.props.value)
      },
    },
    methods: {
      /**
       *
       * @param {{id: string, text: string} | Array<{id: string, text: string}>} value
       */
      setSearch(value) {
        let valueToBeSent = null

        if (this.isMultipleSelect && Array.isArray(value)) {
          valueToBeSent = value.map((option) => option.id)
        } else {
          valueToBeSent = value?.id
        }

        // eslint-disable-next-line vue/no-mutating-props
        this.props.query[this.props.column] = valueToBeSent
        const search = this.props.search(false)
        return search?.()
      },
    },
  }
</script>
