import Vue from 'vue'
import { Can, abilitiesPlugin } from '@casl/vue'
import { AbilityBuilder, Ability } from '@casl/ability'

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Can', Can)
export default ({ app }) => {
  const { can, rules } = new AbilityBuilder()
  if (app.$auth.loggedIn) {
    app.$auth.user.permissions.forEach((permission) => {
      can(...permission)
    })
  }
  Vue.use(abilitiesPlugin, new Ability(rules))
}
