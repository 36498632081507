import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEnvelope as farEnvelope, faUser as farUser, faDotCircle as farDotCircle, faEdit as farEdit } from '@fortawesome/free-regular-svg-icons'
import {
  faEnvelope as fasEnvelope,
  faUser as fasUser,
  faDotCircle as fasDotCircle,
  faBan,
  faPhoneAlt,
  faMobileAlt,
  faMobile,
  faBuilding,
  faTrash,
  faPen,
  faCheck,
  faPlus,
  faEye,
  faMapMarkedAlt,
  faShoppingCart,
  faTrashAlt,
  faCartPlus,
  faEdit,
  faInfoCircle,
  faPrint,
  faInfo,
  faShare,
  faTimes,
  faCheckSquare,
  faMinusSquare,
  faPlusSquare,
  faFile,
  faTags,
  faSquare,
  faAddressCard,
  faGasPump,
  faCloudShowersHeavy,
  faVolumeUp,
  faPeopleArrows,
  faMapMarkerAlt,
  faBusinessTime,
  faMale,
  faRoad,
  faUndo,
  faKey,
  faLock,
  faClone,
  faRedo,
} from '@fortawesome/free-solid-svg-icons'

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(
  fasEnvelope,
  farEnvelope,
  farUser,
  fasUser,
  fasDotCircle,
  farDotCircle,
  faBan,
  faPhoneAlt,
  faMobileAlt,
  faMobile,
  faBuilding,
  faTrash,
  faPen,
  faCheck,
  faPlus,
  faEye,
  faMapMarkedAlt,
  faShoppingCart,
  faTrashAlt,
  faCartPlus,
  faMapMarkedAlt,
  faEdit,
  farEdit,
  faInfoCircle,
  faPrint,
  faInfo,
  faShare,
  faTimes,
  faCheckSquare,
  faMinusSquare,
  faPlusSquare,
  faFile,
  faTags,
  faSquare,
  faAddressCard,
  faGasPump,
  faCloudShowersHeavy,
  faVolumeUp,
  faPeopleArrows,
  faMapMarkerAlt,
  faBusinessTime,
  faMale,
  faRoad,
  faUndo,
  faKey,
  faLock,
  faClone,
  faRedo
)

// Register the component globally
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
