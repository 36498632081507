<template>
  <b-nav-item-dropdown right no-caret class="header-dropdown">
    <template #button-content>
      <img src="~static/img/avatars/9.jpg" class="img-avatar" alt="admin@bootstrapmaster.com" />
    </template>
    <b-dropdown-header tag="div" class="text-left header-dropdown__header">
      <strong>{{ userFullName }}</strong>
    </b-dropdown-header>
    <b-dropdown-item v-b-modal:change-password-modal class="header-dropdown__item">
      <font-awesome-icon :icon="['fas', 'key']" />
      Schimbă parola
      <change-password-modal id="change-password-modal" />
    </b-dropdown-item>
    <b-dropdown-item class="header-dropdown__item" @click="logout">
      <font-awesome-icon :icon="['fa', 'lock']" />
      Deconectare
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
  import { AbilityBuilder } from '@casl/ability'
  import ChangePasswordModal from '@/components/auth/ChangePasswordModal'

  export default {
    name: 'HeaderDropdown',
    components: {
      ChangePasswordModal,
    },

    computed: {
      userFullName() {
        return this.$auth.user.name
      },
    },
    methods: {
      async logout() {
        await this.$auth.logout()
        this.updateAbilities()
        await this.$router.replace({
          name: 'auth-index-signin',
        })
      },

      updateAbilities() {
        const { rules } = new AbilityBuilder()
        this.$ability.update(rules)
      },
      openChangePasswordDialog() {
        alert('test')
      },
    },
  }
</script>

<style lang="scss">
  .header-dropdown {
    .dropdown-menu {
      width: 200px;
    }
  }
</style>
