export const state = () => ({
  counties: {}
})

export const mutations = {
  SET_COUNTIES(state, data) {
    state.counties = data
  }
}

export const actions = {
  async fetchCounties({ commit }) {
    const response = await this.$axios.$get('admin/counties')

    commit('SET_COUNTIES', response.data)
  }
}

export const getters = {
  counties(state) {
    return state.counties
  }
}
