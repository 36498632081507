const ARRIVING_POINTS = {
  57: 'PIPERA',
  58: 'MILITARI',
  124: 'MATEI M',
  125: 'ROTIS',
}

export default ({ app }, inject) => {
  inject('ARRIVING_POINTS', ARRIVING_POINTS)
}

// Vue.prototype.$ARRIVING_POINTS = ARRIVING_POINTS
