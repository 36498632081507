<template>
  <b-alert :show="isImpersonating">
    Momentan impersonați utilizatorul <strong>{{ personBeingImpersonated.full_name }} </strong>, pentru a încheia sesiunea de impersonare click
    <a href="#" @click.prevent="endTheImpersonationSession"><strong>aici</strong> </a>
  </b-alert>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        isImpersonating: 'impersonation/isImpersonating',
        personBeingImpersonated: 'impersonation/personBeingImpersonated'
      })
    },
    beforeMount() {
      this.$store.dispatch('impersonation/getInfoOfCurrentImpersonationSession', {
        path: 'auth/impersonation/impersonate/info'
      })
    },
    methods: {
      ...mapActions({
        endImpersonationSession: 'impersonation/endImpersonationSessions'
      }),
      async endTheImpersonationSession() {
        try {
          const response = await this.endImpersonationSession()
          await this.$auth.setUserToken(response.token)
          window.location.replace('/products')
        } catch (e) {}
      }
    }
  }
</script>
