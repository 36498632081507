/**
 *
 * @param errors string[] | string  | null
 */
export function parseAndConcatenateValidationErrors(errors) {
  if (Array.isArray(errors)) {
    return errors.join('\n')
  }

  return errors
}
