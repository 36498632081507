export const state = () => ({
  impersonator: {},
  persona: {},
  requested_id: null,
  token: null,
  impersonating: false
})

export const mutations = {
  SET_IMPERSONATION_RESULT(state, response) {
    Object.assign(state, response)
  }
}

export const actions = {
  async impersonate({ commit }, path) {
    const response = await this.$axios.$get(path)

    commit('SET_IMPERSONATION_RESULT', response.data)

    return response.data
  },
  async getInfoOfCurrentImpersonationSession({ commit }, { path }) {
    const response = await this.$axios.$get(path)

    commit('SET_IMPERSONATION_RESULT', { ...response.data, impersonating: response.impersonating })

    return response.data
  },
  async endImpersonationSessions({ commit }) {
    const response = await this.$axios.$get('auth/impersonation/impersonate/leave')

    commit('SET_IMPERSONATION_RESULT', {
      ...response.data,
      impersonating: false
    })

    return response.data
  }
}

export const getters = {
  isImpersonating(state) {
    return state.impersonating
  },
  personBeingImpersonated(state) {
    return state.persona
  }
}
