import qs from 'qs'

export default function ({ $axios, redirect, app, isDev }) {
  $axios.onError((error) => {
    if (isDev) {
      if (error.response.status === 500 || error.response.status === 400) {
        app.$toast.error('DEBUG ' + error.response.data.exception + ' ' + error.response.data.file + ' ' + error.response.data.message)
      }

      if (error.response.status === 422) {
        app.$toast.error('DEBUG ' + JSON.stringify(error.response.data.errors))
      }

      if (error.response.status === 403) {
        app.$toast.error('DEBUG ' + JSON.stringify(error.response.data.message))
      }
    }

    if (error.response.status === 401) {
      app.$auth.reset()
      redirect('/auth/signin')
    }
  })

  $axios.onRequest((config) => {
    config.paramsSerializer = (params) => qs.stringify(params)
    return config
  })
}
