export const state = () => ({
  products: [],
  empty: true,
  subtotal_without_vat: null,
  total_without_vat: null,
  subtotal: null,
  delivery_cost: null,
  total: null
})

export const getters = {
  products(state) {
    return state.products
  },
  numberOfProductsInCart(state) {
    return state.products.length
  },
  empty(state) {
    return state.empty
  },
  subtotal(state) {
    return state.subtotal
  },
  deliveryCost(state) {
    return state.delivery_cost
  },
  total(state) {
    return state.total
  },
  subtotalWithoutVat(state) {
    return state.subtotal_without_vat
  },
  totalWithoutVat(state) {
    return state.subtotal_without_vat
  }
}

export const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  SET_EMPTY(state, empty) {
    state.empty = empty
  },
  SET_SUBTOTAL(state, subtotal) {
    state.subtotal = subtotal
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_DELIVERY_COST(state, deliveryCost) {
    state.delivery_cost = deliveryCost
  },
  SET_SUBTOTAL_WITHOUT_VAT(state, subtotal) {
    state.subtotal_without_vat = subtotal
  },
  SET_TOTAL_WITHOUT_VAT(state, total) {
    state.total_without_vat = total
  }
}

export const actions = {
  async getCart({ commit }) {
    const response = await this.$axios.$get('/cart')

    commit('SET_PRODUCTS', response.data.products)
    commit('SET_EMPTY', response.meta.empty)
    commit('SET_DELIVERY_COST', response.meta.delivery_cost)
    commit('SET_SUBTOTAL', response.meta.subtotal)
    commit('SET_TOTAL', response.meta.total)
    commit('SET_SUBTOTAL_WITHOUT_VAT', response.meta.subtotal_without_vat)
    commit('SET_TOTAL_WITHOUT_VAT', response.meta.total_without_vat)

    return response
  },

  async destroy({ dispatch }, productId) {
    await this.$axios.$delete(`/cart/${productId}`)
    dispatch('getCart')
  },

  async update({ dispatch }, { productId, quantity }) {
    await this.$axios.$patch(`/cart/${productId}`, {
      quantity
    })

    dispatch('getCart')
  },
  async addProductToCart({ dispatch }, { productId, quantity }) {
    await this.$axios.$post('/cart', {
      products: [
        {
          id: productId,
          quantity
        }
      ]
    })

    dispatch('getCart')
  },
  async updateDeliveryAddress({ commit }, delivery) {
    const response = await this.$axios.$post(`/cart/updateDeliveryAddress`, {
      delivery
    })

    commit('SET_EMPTY', response.meta.empty)
    commit('SET_DELIVERY_COST', response.meta.delivery_cost)
    commit('SET_SUBTOTAL', response.meta.subtotal)
    commit('SET_TOTAL', response.meta.total)
    commit('SET_SUBTOTAL_WITHOUT_VAT', response.meta.subtotal_without_vat)
    commit('SET_TOTAL_WITHOUT_VAT', response.meta.total_without_vat)
  }
}
