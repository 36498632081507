const permissionsRequiredForCompanies = [
  ['create', 'companies'],
  ['read', 'companies'],
  ['update', 'companies'],
  ['delete', 'companies'],
]
const permissionRequiredForImports = [
  ['create', 'file_imports'],
  ['read', 'file_imports'],
]

const permissionRequiredForUsers = [
  ['create', 'users'],
  ['read', 'users'],
  ['update', 'users'],
  ['delete', 'users'],
]

const permissionRequiredForOrders = [
  ['create', 'admin_orders'],
  ['read', 'admin_orders'],
  ['update', 'admin_orders'],
  ['delete', 'admin_orders'],
]

const permissionRequiredForRoles = [
  ['create', 'roles'],
  ['read', 'roles'],
  ['update', 'roles'],
  ['delete', 'roles'],
]

const permissionRequiredForPermissionsPage = [
  ['read', 'permissions'],
  ['update', 'permissions'],
]

const permissionRequiredForOrdersHistoryPage = [
  ['create', 'orders'],
  ['read', 'orders'],
  ['update', 'orders'],
]

const permissionRequiredForHeadquarterAddressPage = [
  ['create', 'headquarter_addresses'],
  ['read', 'headquarter_addresses'],
  ['update', 'headquarter_addresses'],
  ['delete', 'headquarter_addresses'],
]

const permissionRequiredForRolesAndPermissionsCrud = [...permissionRequiredForRoles, ...permissionRequiredForPermissionsPage]

export default {
  items: [
    // {
    //  name: 'Dashboard',
    //  url: '/',
    //  icon: 'icon-speedometer'
    // },
    {
      name: 'Produse',
      url: '/products',
      icon: 'icon-credit-card',
    },
    {
      name: 'Istoric Comenzi',
      url: '/orders-history',
      icon: 'icon-layers',
      permissionsRequired: permissionRequiredForOrdersHistoryPage,
    },
    {
      title: true,
      name: 'Admin',
      class: '',
      wrapper: {
        element: '',
        attributes: {},
      },
      permissionsRequired: [...permissionRequiredForImports, ...permissionsRequiredForCompanies, ...permissionRequiredForUsers, ...permissionRequiredForOrders],
    },
    {
      name: 'Utilizatori',
      url: '/users',
      icon: 'icon-user',
      permissionsRequired: permissionRequiredForUsers,
    },
    {
      name: 'Imports',
      url: '/imports',
      icon: 'icon-cloud-upload',
      permissionsRequired: permissionRequiredForImports,
    },
    {
      name: 'Companii',
      url: '/companies',
      icon: 'icon-home',
      permissionsRequired: [
        ['create', 'companies'],
        ['read', 'companies'],
        ['update', 'companies'],
        ['delete', 'companies'],
      ],
    },
    {
      name: 'Comenzi',
      url: '/orders',
      icon: 'icon-note',
      permissionsRequired: permissionRequiredForOrders,
    },
    {
      name: 'Costuri transport',
      url: '/delivery-costs',
      icon: 'icon-calculator',
      permissionsRequired: [
        ['create', 'delivery_cost'],
        ['read', 'delivery_cost'],
        ['update', 'delivery_cost'],
        ['delete', 'delivery_cost'],
      ],
    },
    {
      name: 'Importatori',
      url: '/importers',
      icon: 'icon-location-pin',
      permissionsRequired: [
        ['create', 'importers'],
        ['read', 'importers'],
        ['update', 'importers'],
        ['delete', 'importers'],
      ],
    },
    {
      name: 'Adrese companie',
      url: '/headquarter-addresses',
      icon: 'icon-map',
      permissionsRequired: permissionRequiredForHeadquarterAddressPage,
    },
    {
      name: 'Permisiuni și roluri',
      url: '/roles-and-permissions',
      icon: 'icon-settings',
      permissionsRequired: permissionRequiredForRolesAndPermissionsCrud,
      children: [
        {
          name: 'Roluri',
          url: '/roles-and-permissions/roles',
          icon: 'icon-list',
          permissionsRequired: permissionRequiredForRoles,
        },
        {
          name: 'Permisiuni',
          url: '/roles-and-permissions/permissions',
          icon: 'icon-list',
          permissionsRequired: permissionRequiredForPermissionsPage,
        },
      ],
    },
    {
      name: 'Documentatie',
      url: '/docs',
      icon: 'icon-note',
      permissionsRequired: permissionRequiredForOrders,
    },
    // {
    //   title: true,
    //   name: 'Components',
    //   class: '',
    //   wrapper: {
    //     element: '',
    //     attributes: {}
    //   }
    // },
    // {
    //   name: 'Base',
    //   url: '/base',
    //   icon: 'icon-puzzle',
    //   children: [
    //     {
    //       name: 'Breadcrumbs',
    //       url: '/base/breadcrumbs',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Cards',
    //       url: '/base/cards',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Carousels',
    //       url: '/base/carousels',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Collapses',
    //       url: '/base/collapses',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Forms',
    //       url: '/base/forms',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Jumbotrons',
    //       url: '/base/jumbotrons',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'List Groups',
    //       url: '/base/list-groups',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Navs',
    //       url: '/base/navs',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Paginations',
    //       url: '/base/paginations',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Popovers',
    //       url: '/base/popovers',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Progress Bars',
    //       url: '/base/progress-bars',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Switches',
    //       url: '/base/switches',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Tables',
    //       url: '/base/tables',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Tooltips',
    //       url: '/base/tooltips',
    //       icon: 'icon-puzzle'
    //     }
    //   ]
    // }
    // {
    //   name: 'Buttons',
    //   url: '/buttons',
    //   icon: 'icon-cursor',
    //   children: [
    //     {
    //       name: 'Standard Buttons',
    //       url: '/buttons/standard-buttons',
    //       icon: 'icon-cursor'
    //     },
    //     {
    //       name: 'Button Groups',
    //       url: '/buttons/button-groups',
    //       icon: 'icon-cursor'
    //     },
    //     {
    //       name: 'Dropdowns',
    //       url: '/buttons/dropdowns',
    //       icon: 'icon-cursor'
    //     },
    //     {
    //       name: 'Social Buttons',
    //       url: '/buttons/social-buttons',
    //       icon: 'icon-cursor'
    //     }
    //   ]
    // }
    // {
    //   name: 'Icons',
    //   url: '/icons',
    //   icon: 'icon-star',
    //   children: [
    //     {
    //       name: 'Flags',
    //       url: '/icons/flags',
    //       icon: 'icon-star',
    //       badge: {
    //         variant: 'success',
    //         text: 'NEW'
    //       }
    //     },
    //     {
    //       name: 'Font Awesome',
    //       url: '/icons/font-awesome',
    //       icon: 'icon-star',
    //       badge: {
    //         variant: 'secondary',
    //         text: '4.7'
    //       }
    //     },
    //     {
    //       name: 'Simple Line Icons',
    //       url: '/icons/simple-line-icons',
    //       icon: 'icon-star'
    //     }
    //   ]
    // },
    // {
    //   name: 'Charts',
    //   url: '/charts',
    //   icon: 'icon-pie-chart'
    // },
    // {
    //   name: 'Notifications',
    //   url: '/notifications',
    //   icon: 'icon-bell',
    //   children: [
    //     {
    //       name: 'Alerts',
    //       url: '/notifications/alerts',
    //       icon: 'icon-bell'
    //     },
    //     {
    //       name: 'Badges',
    //       url: '/notifications/badges',
    //       icon: 'icon-bell'
    //     },
    //     {
    //       name: 'Modals',
    //       url: '/notifications/modals',
    //       icon: 'icon-bell'
    //     }
    //   ]
    // },
    // {
    //   name: 'Widgets',
    //   url: '/widgets',
    //   icon: 'icon-calculator',
    //   badge: {
    //     variant: 'primary',
    //     text: 'NEW'
    //   }
    // },
    // {
    //   divider: true
    // },
    // {
    //   title: true,
    //   name: 'Extras'
    // },
    // {
    //   name: 'Pages',
    //   url: '/pages',
    //   icon: 'icon-star',
    //   children: [
    //     {
    //       name: 'Login',
    //       url: '/pages/login',
    //       icon: 'icon-star'
    //     },
    //     {
    //       name: 'Register',
    //       url: '/pages/register',
    //       icon: 'icon-star'
    //     },
    //     {
    //       name: 'Error 404',
    //       url: '/pages/404',
    //       icon: 'icon-star'
    //     },
    //     {
    //       name: 'Error 500',
    //       url: '/pages/500',
    //       icon: 'icon-star'
    //     }
    //   ]
    // }
  ],
}
