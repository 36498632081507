export const state = () => ({
  lastOrderId: null,
  showModal: false,
})

export const mutations = {
  setOrderId(state, orderId) {
    state.lastOrderId = orderId
  },
  setShowModal(state, showModal) {
    state.showModal = showModal
  },
}

export const actions = {
  submitOrder({ commit }, orderId) {
    commit('setOrderId', orderId)
    commit('setShowModal', true)
  },
}

export const getters = {
  lastOrderId(state) {
    return state.lastOrderId
  },
  showModal(state) {
    return state.showModal
  },
}
