<template>
  <b-modal title="Pentru a schimba parola completează formularul de mai jos" centered size="lg" header-bg-variant="secondary" header-text-variant="light" v-bind="$attrs">
    <template #default>
      <b-form novalidate @submit.prevent="handleSubmit">
        <b-form-group id="current-password-field" label="Parola curentă" :invalid-feedback="currentPasswordErrors" :state="currentPasswordFieldSate">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="icon-lock"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input id="current_password" v-model="form.current_password" :state="currentPasswordFieldSate" type="password" required autocomplete="off" />
          </b-input-group>
        </b-form-group>
        <b-form-group id="password-field" label="Parolă nouă" :invalid-feedback="passwordErrors" :state="passwordFieldState">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="icon-lock"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input id="password" v-model="form.password" :state="passwordFieldState" type="password" required autocomplete="off" />
          </b-input-group>
          <template #description>
            <password-field-description :should-show="passwordFieldState" />
          </template>
        </b-form-group>
        <b-form-group id="password-confirmation-field" label="Confirmă parolă nouă">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="icon-lock"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input id="password-confirmation" v-model="form.password_confirmation" type="password" required autocomplete="off" />
          </b-input-group>
        </b-form-group>
      </b-form>
    </template>
    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="success" @click="handleSubmit(ok)"> Salvează</b-button>
      <b-button size="sm" variant="danger" @click="cancel()"> Anulează</b-button>
    </template>
  </b-modal>
</template>
<script>
  import { parseAndConcatenateValidationErrors } from '@/helpers'
  import PasswordFieldDescription from '@/components/auth/password-field-description.vue'

  export default {
    name: 'ChangePasswordModal',
    components: { PasswordFieldDescription },
    data() {
      return {
        errors: {},
        form: {
          current_password: null,
          password: null,
          password_confirmation: null,
        },
        loading: false,
      }
    },
    computed: {
      currentPasswordFieldSate() {
        return !Object.hasOwn(this.errors, 'current_password') ? null : false
      },
      currentPasswordErrors() {
        return this.errors.current_password ? parseAndConcatenateValidationErrors(this.errors.current_password) : null
      },
      passwordErrors() {
        return this.errors.password ? parseAndConcatenateValidationErrors(this.errors.password) : null
      },
      passwordFieldState() {
        return !Object.hasOwn(this.errors, 'password') ? null : false
      },
    },
    methods: {
      async handleSubmit(callback) {
        this.loading = true
        try {
          this.resetErrors()
          const {
            data: { status },
          } = await this.$axios.patch('/auth/change-password', this.form)
          if (callback) {
            callback()
          }
          this.$toast.success(status)
          this.resetForm()
        } catch (error) {
          this.errors = error?.response?.data?.errors || {}

          this.$toast.error('Nu am reușit să schimbăm parola')
        } finally {
          this.loading = false
        }
      },
      resetErrors() {
        this.errors = {}
      },
      resetForm() {
        this.form = {
          current_password: null,
          password: null,
          password_confirmation: null,
        }
      },
    },
  }
</script>
