<template>
  <div class="app">
    <AppHeader />
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :nav-items="navItems()"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main">
        <impersonation-status />
        <div class="container-fluid" :class="{ 'mt-0': isImpersonating, 'mt-4': !isImpersonating }">
          <nuxt />
        </div>
      </main>
    </div>

    <TheFooter> © 2022 Webtrade Marketing SRL. Toate drepturile rezervate. </TheFooter>
  </div>
</template>
<script>
  import { Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Footer as TheFooter } from '@coreui/vue'
  import { mapGetters } from 'vuex'
  import nav from './menu'
  import { Header as AppHeader } from '@/components/index'
  import ImpersonationStatus from '@/components/Impersonation/ImpersonationStatus'

  export default {
    name: 'Full',
    components: {
      AppHeader,
      AppSidebar,
      TheFooter,
      SidebarNav,
      SidebarMinimizer,
      SidebarHeader,
      SidebarForm,
      SidebarFooter,
      ImpersonationStatus,
    },
    data() {
      return {
        nav: nav.items,
      }
    },
    computed: {
      name() {
        return this.$route.name
      },
      list() {
        return this.$route.matched
      },
      ...mapGetters({
        isImpersonating: 'impersonation/isImpersonating',
      }),
    },
    methods: {
      navItems() {
        const checkPermissions = (navItem) => {
          return navItem.permissionsRequired.some((permission) => {
            return this.$can(...permission)
          })
        }

        if (this.$auth.loggedIn) {
          return nav.items.reduce((result, currentNavItem) => {
            if (Object.hasOwn(currentNavItem, 'permissionsRequired') && currentNavItem.permissionsRequired.length) {
              if (!checkPermissions(currentNavItem)) {
                return result
              }

              if (Object.hasOwn(currentNavItem, 'children') && currentNavItem.children.length) {
                return [
                  ...result,
                  {
                    ...currentNavItem,
                    children: currentNavItem.children.filter((navItemChild) => {
                      if (Object.hasOwn(navItemChild, 'permissionsRequired') && navItemChild.permissionsRequired.length) {
                        return checkPermissions(navItemChild)
                      }
                      return true
                    }),
                  },
                ]
              }
            }
            return [...result, currentNavItem]
          }, [])
        }

        return []
      },
    },
  }
</script>
