<template>
  <AppHeader fixed>
    <SidebarToggler class="d-lg-none" display="md" mobile />
    <b-link class="navbar-brand" to="#">
      <img class="navbar-brand-full" src="~static/img/brand/logo.svg" width="130" height="35" alt="CoreUI Logo" />
      <img class="navbar-brand-minimized" src="~static/img/brand/sygnet.svg" width="30" height="30" alt="CoreUI Logo" />
    </b-link>
    <SidebarToggler :default-open="true" class="d-md-down-none" display="lg" />
    <!--    <b-navbar-nav class="d-md-down-none">-->
    <!--      <b-nav-item class="px-3">Dashboard</b-nav-item>-->
    <!--      <b-nav-item class="px-3">Users</b-nav-item>-->
    <!--      <b-nav-item class="px-3">Settings</b-nav-item>-->
    <!--    </b-navbar-nav>-->
    <b-navbar-nav class="ml-auto">
      <!--      <b-nav-item class="d-md-down-none">-->
      <!--        <i class="icon-bell"></i>-->
      <!--        <b-badge pill variant="danger">5</b-badge>-->
      <!--      </b-nav-item>-->
      <HeaderCartDropdown />
      <HeaderDropdown />
    </b-navbar-nav>
    <!--    <button-->
    <!--      class="navbar-toggler aside-menu-toggler d-md-down-none"-->
    <!--      type="button"-->
    <!--      @click="asideToggle"-->
    <!--    >-->
    <!--      <span class="navbar-toggler-icon"></span>-->
    <!--    </button>-->
  </AppHeader>
</template>

<script>
  import { SidebarToggler, Header as AppHeader } from '@coreui/vue'
  import HeaderDropdown from './HeaderDropdown'
  import HeaderCartDropdown from './HeaderCartDropdown'

  export default {
    name: 'CHeader',
    components: {
      HeaderDropdown,
      HeaderCartDropdown,
      SidebarToggler,
      AppHeader
    }
  }
</script>
