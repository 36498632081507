import Vue from 'vue'
import { ServerTable } from 'vue-tables-2-premium'
import { omit } from 'lodash'
import VtTableListFilter from '~/components/VtTableListFilter'

export class ParamsAdapter {
  propertiesToBeOmitted = ['query', 'byColumn', 'ascending', 'orderBy', 'multiSort']

  constructor(data) {
    this.data = data
  }

  static constructFilters(object) {
    const filters = {}
    const excludedProps = ['limit'];

    for (const property in object) {
      // eslint-disable-next-line no-prototype-builtins
      if (object.hasOwnProperty(property) && !excludedProps.includes(property)) {
        if (object[property] instanceof Object && !Array.isArray(object[property])) {
          filters[property] = ParamsAdapter.constructFilters(object[property])
        }

        if (Array.isArray(object[property])) {
          filters[property] = object[property].join(',')
        }

        if (object[property] instanceof String || typeof object[property] === 'string') {
          filters[property] = object[property].trim().replace(/(^,)|(,$)/g, '')
        }
      }
    }

    return filters
  }

  static constructSingleSort({ ascending, column }) {
    const direction = ascending ? '' : '-'
    return `${direction}${column || 'id'}`
  }

  static constructSort({ orderBy, ascending, multiSort = [] }) {
    if (!orderBy) return {}

    const listOfSortInstructions = [
      {
        column: orderBy,
        ascending,
      },
      ...multiSort,
    ]

    return {
      sort: listOfSortInstructions
        .reduce((previousValue, currentItem) => `${previousValue},${this.constructSingleSort(currentItem)}`, '')
        .trim()
        .replace(/(^,)|(,$)/g, ''),
    }
  }

  toJSON() {
    return {
      filter: this.constructor.constructFilters({
        ...omit(this.data, this.propertiesToBeOmitted),
        ...this.data.query,
      }),
      ...this.constructor.constructSort(this.data),
      ...omit(this.data, this.propertiesToBeOmitted),
    }
  }
}

export const options = {
  responseAdapter(response) {
    return {
      data: response.data.data,
      count: response.data.meta.total,
    }
  },
  sortIcon: {
    base: 'fa fas',
    is: 'fa-sort',
    up: 'fa-sort-alpha-asc',
    down: 'fa-sort-alpha-desc',
  },
  texts: {
    count: 'Se afișează de la {from} la {to} din {count} înregistrări | {count} înregistrări | O înregistrare',
    first: 'Prima',
    last: 'Ultima',
    filter: 'Filtru:',
    filterPlaceholder: 'Termen de căutare',
    limit: '',
    page: 'Pagină:',
    noResults: 'Nu au fost găsite rezultate',
    filterBy: '',
    loading: 'Încărcare...',
    defaultOption: '',
    columns: 'Coloane',
  },
  theme: 'bootstrap4',
  useVuex: true,
  datepickerOptions: {
    locale: {
      format: 'MM/DD/YYYY',
      separator: ' - ',
      applyLabel: 'Aplică',
      cancelLabel: 'Anulează',
      fromLabel: 'De la',
      toLabel: 'Pana la',
      customRangeLabel: 'Custom',
      daysOfWeek: ['Du', 'Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sâ'],
      monthNames: ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'],
      firstDay: 1,
    },
  },
}

export default ({ app: { $axios } }) => {
  Vue.use(
    ServerTable,
    {
      requestFunction(data) {
        return $axios
          .get(this.url, {
            params: new ParamsAdapter(data).toJSON(),
          })
          .catch(
            function (e) {
              this.dispatch('error', e)
            }.bind(this)
          )
      },
      ...options,
    },
    false,
    'bootstrap4',
    {
      listFilter: VtTableListFilter,
    }
  )
}
