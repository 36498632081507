<template>
  <span v-if="showComputed"> Parola trebuie să conțină cel puțin 8 caractere, o literă mare, o literă mică, un simbol si un număr. </span>
</template>
<script>
  export default {
    props: {
      shouldShow: {
        type: [Boolean],
      },
    },

    computed: {
      showComputed() {
        return this.shouldShow === null
      },
    },
  }
</script>
