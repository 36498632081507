export const state = () => ({
  importers: {},
})

export const mutations = {
  SET_IMPORTERS(state, importers) {
    state.importers = importers
  },
}

export const actions = {
  async fetchImporters({ commit }) {
    const response = await this.$axios.$get('admin/importers/all')

    commit('SET_IMPORTERS', response.data)
  },
}

export const getters = {
  importers(state) {
    return state.importers
  },
}
